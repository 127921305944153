<script>
/**
 * Sales Analytics component
 */
export default {
  props: {
    mode: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 230,
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902", "#ff3d60", "#27AE60", "#2B9FDC", "#0077DC", "#FF5E1E", "#FFB800", "#42C157", "#4e6a9e"],
      },
    }
  },
  computed: {
    chartData() {
      const chartData = {
        daily: {
          series: [],
          labels: [],
          total: null
        },
        weekly: {
          series: [],
          labels: [],
          total: null
        },
        monthly: {
          series: [],
          labels: [],
          total: null
        },
      }
      if (this.data) {
        if (this.data.daily_best) {
          chartData.daily.series = this.data.daily_best.map(
            (element) => element.count
          )
          chartData.daily.labels = this.data.daily_best.map(
            (element) => element.label
          )
          chartData.daily.total = this.data.daily_best.reduce(
            (inc, element) => inc + element.count, 0
          )
        }
        if (this.data.weekly_best) {
          chartData.weekly.series = this.data.weekly_best.map(
            (element) => element.count
          )
          chartData.weekly.labels = this.data.weekly_best.map(
            (element) => element.label
          )
          chartData.weekly.total = this.data.weekly_best.reduce(
            (inc, element) => inc + element.count, 0
          )
        }
        if (this.data.monthly_best) {
          chartData.monthly.series = this.data.monthly_best.map(
            (element) => element.count
          )
          chartData.monthly.labels = this.data.monthly_best.map(
            (element) => element.label
          )
          chartData.monthly.total = this.data.monthly_best.reduce(
            (inc, element) => inc + element.count, 0
          )
        }
      }
      return chartData
    },
  },
  methods: {
    getDiffPercentage(value1, value2) {
      return ((value1 / value2) * 100).toFixed(2)
    },
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <!-- <h4 class="card-title mb-4">(.*)</h4> -->

      <div id="donut-chart" class="apex-charts"></div>
      <apexchart
        class="apex-charts"
        height="230"
        dir="ltr"
        :series="chartData[mode].series"
        :options="{
          ...chartOptions,
          labels: chartData[mode].labels,
        }"
      ></apexchart>
      <div class="row">
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>
              {{ chartData[mode].labels[0] }}
            </p>
            <h5>{{ getDiffPercentage(chartData[mode].series[0], chartData[mode].total) }} %</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
              {{ chartData[mode].labels[1] }}
            </p>
            <h5>{{ getDiffPercentage(chartData[mode].series[1], chartData[mode].total) }} %</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i>
              {{ chartData[mode].labels[2] }}
            </p>
            <h5>{{ getDiffPercentage(chartData[mode].series[2], chartData[mode].total) }} %</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>