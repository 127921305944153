<script>
/**
 * Revenue Analytics component
 */

export default {
  props: {
    mode: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c"],
        yaxis: {
          labels: {
            formatter: (value) => {
              return this.displayPrice(value)
            }
          }
        }
      }
    }
  },
  computed: {
    currModeLabel() {
      return this.mode == "daily"
        ? "Today"
        : this.mode == "weekly"
          ? "This Week"
          : "This Month"
    },
    lastModeLabel() {
      return this.mode == "daily"
        ? "Yesterday"
        : this.mode == "weekly"
          ? "Last Week"
          : "Last Month"
    },
    chartData() {
      const chartData = {
        daily: {
          series: [],
          labels: [],
          total: null,
          diff: null,
        },
        weekly: {
          series: [],
          labels: [],
          total: null,
          diff: null,
        },
        monthly: {
          series: [],
          labels: [],
          total: null,
          diff: null,
        },
      }

      if (this.data) {
        chartData.daily.series.push({
          name: "Today",
          type: "column",
          data: this.data.daily.map((element) => element.sales),
        })
        chartData.daily.series.push({
          name: "Last Day",
          type: "line",
          data: this.data.last_daily.map((element) => element.sales),
        })
        chartData.daily.labels = this.data.daily.map(
          (element) => element.label
        )
        chartData.daily.total = this.data.daily.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.daily.lastTotal = this.data.last_daily.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.daily.diffPercent = this.getDiffPercentage(
          chartData.daily.total,
          chartData.daily.lastTotal
        )

        chartData.weekly.series.push({
          name: "This Week",
          type: "column",
          data: this.data.weekly.map((element) => element.sales),
        })
        chartData.weekly.series.push({
          name: "Last Week",
          type: "line",
          data: this.data.last_weekly.map((element) => element.sales),
        })
        chartData.weekly.labels = this.data.weekly.map(
          (element) => element.label
        )
        chartData.weekly.total = this.data.weekly.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.weekly.lastTotal = this.data.last_weekly.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.weekly.diffPercent = this.getDiffPercentage(
          chartData.weekly.total,
          chartData.weekly.lastTotal
        )

        chartData.monthly.series.push({
          name: "This Month",
          type: "column",
          data: this.data.monthly.map((element) => element.sales),
        })
        chartData.monthly.series.push({
          name: "Last Month",
          type: "line",
          data: this.data.last_monthly.map((element) => element.sales),
        })
        chartData.monthly.labels = this.data.monthly.map(
          (element) => element.label
        )
        chartData.monthly.total = this.data.monthly.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.monthly.lastTotal = this.data.last_monthly.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.monthly.diffPercent = this.getDiffPercentage(
          chartData.monthly.total,
          chartData.monthly.lastTotal
        )
      }

      return chartData
    },
  },
  methods: {
    getDiffPercentage(value1, value2) {
      return ((value1 / value2) * 100 - 100).toFixed(2)
    },
    displayPrice(price) {
      if (Number.isInteger(price)) {
        return new Intl.NumberFormat('id-id', {
          style: 'currency',
          currency: 'IDR',
          maximumFractionDigits: 0,
        }).format(price)
      } else {
        return price
      }
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Total Revenue</h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="chartData[mode].series"
          :options="{
            ...chartOptions,
            labels: chartData[mode].labels,
          }"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
      <div class="row">
        <div class="col-sm-6">
          <div class="d-inline-flex">
            <h5 class="mr-2">{{ displayPrice(chartData[mode].total) }}</h5>
            <div
              :class="
                chartData[mode].diffPercent > 0 ? 'text-success' : 'text-danger'
              "
            >
              <i
                class="mdi font-size-14"
                :class="
                  chartData[mode].diffPercent > 0
                    ? 'mdi-menu-up'
                    : 'mdi-menu-down'
                "
              ></i>
              {{ chartData[mode].diffPercent }} %
            </div>
          </div>
          <p class="text-muted text-truncate mb-0">{{ currModeLabel }}</p>
        </div>

        <div class="col-sm-6">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
              {{ lastModeLabel }}
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0">
                {{ displayPrice(chartData[mode].lastTotal) }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>