<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./stat";
import CountAnalytics from "./count";
import SalesAnalytics from "./sales";
import ProductDistribution from "./product-distribution";
import PaymentMethodReport from "./payment-method";
import Machines from "./machines";
import Transaction from './../transactions/list';

import { reportService } from "../../../helpers/backend/report.service";

export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    CountAnalytics,
    SalesAnalytics,
    ProductDistribution,
    PaymentMethodReport,
    Machines,
    Transaction
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "VMCore"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      currMode: 'daily',
      statData: null,
      chartData: null
    };
  },
  created() {
    this.fetchChartData(new Date().toISOString())
    this.fetchStatData(new Date().toISOString())
  },
  methods: {
    changeMode(mode) {
      this.currMode = mode
    },
    fetchChartData(date) {
      reportService.getReportChart(date)
        .then(data => {
          this.chartData = data.report
        })
    },
    fetchStatData(date) {
      reportService.getReportStats(date)
        .then(data => {
          this.statData = data.report
        })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
      <div class="col-xl-12">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            :class="{'active': currMode === 'daily'}"
            @click="changeMode('daily')"
          >
            Daily
          </button>
           <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            :class="{'active': currMode === 'weekly'}"
            @click="changeMode('weekly')"
          >
            Weekly
          </button>
           <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            :class="{'active': currMode === 'monthly'}"
            @click="changeMode('monthly')"
          >
            Monthly
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8">
        <Stat :mode="currMode" :data="statData"/>
        <CountAnalytics :mode="currMode" :data="chartData"/>
        <SalesAnalytics :mode="currMode" :data="chartData"/>
      </div>
      <div class="col-xl-4">
        <ProductDistribution :mode="currMode" :data="statData"/>
        <PaymentMethodReport :mode="currMode" :data="statData"/>
        <Machines :mode="currMode" :data="chartData"/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <Transaction />
      </div>
    </div>
  </Layout>
</template>