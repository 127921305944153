<script>
export default {
  props: {
    mode: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    statData() {
      let statData = {
        count: {
          title: "Jumlah Penjualan",
          icon: "ri-stack-line",
          value: {},
          subvalue: {},
        },
        sales: {
          title: "Pendapatan Penjualan",
          icon: "ri-store-2-line",
          value: {},
          subvalue: {},
        },
        best: {
          title: "Produk Terlaku",
          icon: "ri-line-chart-line",
          value: {},
          subvalue: {},
        },
      }
      if (this.data) {
        statData.count.value = {
          daily: this.data.daily_transaction,
          weekly: this.data.weekly_transaction,
          monthly: this.data.monthly_transaction,
        }
        statData.count.subvalue = {
          daily: this.getDiffPercentage(
            this.data.daily_transaction,
            this.data.last_daily_transaction
          ),
          weekly: this.getDiffPercentage(
            this.data.weekly_transaction,
            this.data.last_weekly_transaction
          ),
          monthly: this.getDiffPercentage(
            this.data.monthly_transaction,
            this.data.last_monthly_transaction
          ),
        }
        statData.sales.value = {
          daily: this.data.daily_gross,
          weekly: this.data.weekly_gross,
          monthly: this.data.monthly_gross,
        }
        statData.sales.subvalue = {
          daily: this.getDiffPercentage(
            this.data.daily_gross,
            this.data.last_daily_gross
          ),
          weekly: this.getDiffPercentage(
            this.data.weekly_gross,
            this.data.last_weekly_gross
          ),
          monthly: this.getDiffPercentage(
            this.data.monthly_gross,
            this.data.last_monthly_gross
          ),
        }
        statData.best.value = {
          daily: this.data.daily_best
            ? this.data.daily_best[0].label
            : '',
          weekly: this.data.weekly_best
            ? this.data.weekly_best[0].label
            : '',
          monthly: this.data.monthly_best
            ? this.data.monthly_best[0].label
            : '',
        }
      }
      return statData
    },
  },
  methods: {
    getDiffPercentage(value1, value2) {
      return ((value1 / value2) * 100 - 100).toFixed(2)
    },
    displayPrice(price) {
      if (Number.isInteger(price)) {
        return new Intl.NumberFormat('id-id', {
          style: 'currency',
          currency: 'IDR',
          maximumFractionDigits: 0,
        }).format(price)
      } else {
        return price
      }
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">
                {{ statData.count.title }}
              </p>
              <h4 class="mb-0">{{ statData.count.value[mode] }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${statData.count.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span
              class="badge font-size-11"
              :class="
                statData.count.subvalue[mode] > 0
                  ? 'badge-soft-success'
                  : 'badge-soft-danger'
              "
            >
              <i
                class="mdi"
                :class="
                  statData.count.subvalue[mode] > 0
                    ? 'mdi-menu-up'
                    : 'mdi-menu-down'
                "
              ></i>
              {{ statData.count.subvalue[mode] }}
            </span>
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">
                {{ statData.sales.title }}
              </p>
              <h4 class="mb-0">
                {{ displayPrice(statData.sales.value[mode]) }}
              </h4>
            </div>
            <div class="text-primary">
              <i :class="`${statData.sales.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span
              class="badge font-size-11"
              :class="
                statData.count.subvalue[mode] > 0
                  ? 'badge-soft-success'
                  : 'badge-soft-danger'
              "
            >
              <i
                class="mdi"
                :class="
                  statData.count.subvalue[mode] > 0
                    ? 'mdi-menu-up'
                    : 'mdi-menu-down'
                "
              ></i>
              {{ statData.sales.subvalue[mode] }}
            </span>
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">
                {{ statData.best.title }}
              </p>
              <h4 class="mb-0">{{ statData.best.value[mode] }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${statData.best.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>