<script>
/**
 * Sources Component
 */
export default {
  props: {
    mode: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    chartData() {
      const chartData = {
        daily: {
          total: null,
          diff: null,
          machines: []
        },
        weekly: {
          total: null,
          diff: null,
          machines: []
        },
        monthly: {
          total: null,
          diff: null,
          machines: []
        },
      }

      if (this.data) {
        if (this.data.daily_machines) {
          chartData.daily.machines = this.data.daily_machines
        }
        chartData.daily.total = this.data.daily.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.daily.lastTotal = this.data.last_daily.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.daily.diffPercent = this.getDiffPercentage(
          chartData.daily.total,
          chartData.daily.lastTotal
        )

        if (this.data.weekly_machines) {
          chartData.weekly.machines = this.data.weekly_machines
        }
        chartData.weekly.total = this.data.weekly.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.weekly.lastTotal = this.data.last_weekly.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.weekly.diffPercent = this.getDiffPercentage(
          chartData.weekly.total,
          chartData.weekly.lastTotal
        )

        if (this.data.monthly_machines) {
          chartData.monthly.machines = this.data.monthly_machines
        }
        chartData.monthly.total = this.data.monthly.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.monthly.lastTotal = this.data.last_monthly.reduce(
          (inc, element) => inc + element.sales,
          0
        )
        chartData.monthly.diffPercent = this.getDiffPercentage(
          chartData.monthly.total,
          chartData.monthly.lastTotal
        )
      }

      return chartData
    }
  },
  methods: {
    getDiffPercentage(value1, value2) {
      return ((value1 / value2) * 100 - 100).toFixed(2)
    },
    displayPrice(price) {
      if (Number.isInteger(price)) {
        return new Intl.NumberFormat('id-id', {
          style: 'currency',
          currency: 'IDR',
          maximumFractionDigits: 0,
        }).format(price)
      } else {
        return price
      }
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div>
        <div class="text-center">
          <p class="mb-2">Total revenue</p>
          <div class="d-inline-flex">
            <h4 class="mr-2">{{ displayPrice(chartData[mode].total) }}</h4>
            <span
              :class="
                chartData[mode].diffPercent > 0 ? 'text-success' : 'text-danger'
              "
            >
              <i
                class="mdi font-size-14"
                :class="
                  chartData[mode].diffPercent > 0
                    ? 'mdi-menu-up'
                    : 'mdi-menu-down'
                "
              ></i>
              {{ chartData[mode].diffPercent }} %
            </span>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mb-0 table-centered table-nowrap">
            <thead>
              <tr>
                <th>Machine</th>
                <th>Revenue</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h5 class="font-size-14 mb-0">
                    {{ chartData[mode].machines[0].label }}
                  </h5>
                </td>
                <td>
                  <p class="text-muted mb-0">
                    {{ displayPrice(chartData[mode].machines[0].sales) }}
                  </p>
                </td>
                <td>
                  <p class="text-muted mb-0">
                    {{ chartData[mode].machines[0].count }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="font-size-14 mb-0">
                    {{ chartData[mode].machines[1].label }}
                  </h5>
                </td>
                <td>
                  <p class="text-muted mb-0">
                    {{ displayPrice(chartData[mode].machines[1].sales) }}
                  </p>
                </td>
                <td>
                  <p class="text-muted mb-0">
                    {{ chartData[mode].machines[1].count }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="font-size-14 mb-0">
                    {{ chartData[mode].machines[2].label }}
                  </h5>
                </td>
                <td>
                  <p class="text-muted mb-0">
                    {{ displayPrice(chartData[mode].machines[2].sales) }}
                  </p>
                </td>
                <td>
                  <p class="text-muted mb-0">
                    {{ chartData[mode].machines[2].count }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center mt-4">
          <a href="#" class="btn btn-primary btn-sm">View more</a>
        </div>
      </div>
    </div>
  </div>
</template>